<template>
  <DashboardContainer>
  <services></services>
  </DashboardContainer> 
</template>

<script>
import DashboardContainer from "../containers/DashboardContainer";

import services from '../components/services/Services'

export default {
  name: "Dashboard",
  data() {
    return {
      allEmployees: [],
      assignTo: "",
      //LDetail: {},
      manager_name: "",
    };
  },
  beforeCreate: function () {
    var auth = this.$storage.get("auth");
    if (!auth) {
      this.$router.push("/login");
    } else {
      if (!auth.token) {
        this.$router.push("/login");
      }
    }
  },
  methods: {
   
  },
  components: {
    DashboardContainer,
    services
  
  },
  mounted() {
   
  },
};
</script>